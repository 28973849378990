<template>
    <span v-if="this.options">{{computedValue}}</span>

</template>

<script>
import Cell from './cell';

export default {
    mixins: [ Cell ],

    computed: {

        computedValue() {
            if(this.value && this.props.trackBy && this.props.label && this.options && this.props.optionKey && this.options[this.props.optionKey]) {
                let value = this.options[this.props.optionKey].find(option => option[this.props.trackBy] === this.value);
                return value ? value[this.props.label] : this.value;
            }
            return this.value;
        },
    },
}
</script>
