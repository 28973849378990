var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.options
    ? _c("span", [_vm._v(_vm._s(_vm.computedValue))])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }