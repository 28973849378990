var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "crud-table",
        {
          ref: "table",
          attrs: {
            api: "contacts",
            "soft-delete": true,
            label: "Kontakte",
            name: { singular: "Kontakt", plural: "Kontakte" },
            filter: _vm.params,
            columns: _vm.columns,
          },
          on: {
            add: _vm.openForm,
            edit: _vm.openForm,
            loaded: (opt) =>
              _vm.$store.commit("updateOptions", { contacts: opt.options }),
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function (slotProps) {
                return [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.titles.find(
                          (title) => title.key === slotProps.row.title
                        )
                          ? _vm.titles.find(
                              (title) => title.key === slotProps.row.title
                            ).value
                          : ""
                      ) +
                      "\n            "
                  ),
                ]
              },
            },
            {
              key: "role",
              fn: function (slotProps) {
                return [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.roles.find(
                          (title) => title.key === slotProps.row.role
                        )
                          ? _vm.roles.find(
                              (title) => title.key === slotProps.row.role
                            ).value
                          : ""
                      ) +
                      "\n            "
                  ),
                ]
              },
            },
            {
              key: "type",
              fn: function (slotProps) {
                return [
                  slotProps.row.type === "primary"
                    ? _c("i", { staticClass: "material-icons" }, [
                        _vm._v("check"),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: "form",
              fn: function ({ options }) {
                return _c("item-form", {
                  ref: "form",
                  attrs: { formType: "modal" },
                  on: {
                    success: function ($event) {
                      return _vm.$refs.table.refresh()
                    },
                  },
                })
              },
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "level level-left" }, [
                    _c(
                      "a",
                      {
                        staticClass: "button level-item is-primary",
                        on: { click: _vm.openForm },
                      },
                      [_vm._v("Kontakt hinzufügen")]
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("h2", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v("Kontakte"),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }